import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW',
    },
  },

  
  {
    name: 'Categories',
    url: '/images/Categories-list',
    icon: 'cil-image-plus',
  },
  {
    name: 'NEWS',
    url: '/catalogue/news-list',
    icon: 'cil-map',
  },
  {
    name: 'Polls Management',
    url: '/advertise/Polls-list',
    icon: 'cil-image-plus',
  },
  {
    name: 'Video Management',
    url: '/notification/video-list',
    icon: 'cil-image-plus',
  },
  {
    name: 'Image Slider',
    url: '/offer/image-list',
    icon: 'cil-image-plus',
  },

  // {
  //   name: 'Nagpur City',
  //   url: '/businessType/business-list',
  //   icon: 'cil-map',
  // },
  // {
  //   name: 'Nagpur Crime',
  //   url: '/category/category-list',
  //   icon: 'cil-window-maximize',
  // },

  // {
  //   name: 'Special Story',
  //   url: '/subCategory/subCategory-list',
  //   icon: 'cil-window-maximize',
  // },

  // {
  //   name: 'Astha',
  //   url: '/offer/offer-list',
  //   icon: 'cil-image-plus',
  // },

  // {
  //   name: 'Polls Managment',
  //   url: '/users/users',
  //   icon: 'cil-settings',
  // },
  // {
  //   name: 'Profile',
  //   url: '/users/users',
  //   icon: 'cil-settings',
  // }, {
  //   name: 'Logout',
  //   url: '/users/users',
  //   icon: 'cil-settings',
  // },
];
