import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ApiService } from 'src/app/core/services';
@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  routes: any = {
    creatPath: '/category/create',
    getAllPath: '/category/getAll',
    updatePath: (id: string) => `/category/update/${id}`,
    getByIdPath: (id: string) => `/category/getBy/${id}`,
    deletePath: (id: string) => `/category/delete/${id}`,
  };
  constructor(private http: ApiService) {}

  create(payload: any) {
    return this.http.post(this.routes.creatPath, payload);
  }
  getAll(payload: any) {
    return this.http.get(this.routes.getAllPath, payload);
  } 
  update(id:string,payload: any) {
    return this.http.put(this.routes.updatePath(id), payload);
  }
   getById(id:string) {
    return this.http.get(this.routes.getByIdPath(id) );
  }
   delete(id:string) {
    return this.http.delete(this.routes.deletePath(id) );
  }
}
